$platform-name: 'dunmorelangcollegesltd';
$assets-path: "../assets/";

$brand-primary: #72CDF4;
$brand-secondary: #414042;
$brand-tertiary: #E03A3E;
$brand-four: #F2EFED;
$grey-lightest: #F2EFED;
$donate-colour: $brand-tertiary;
$black: $brand-secondary;
$link-colour: darken($brand-primary, 20%);



// layout
$container-max-width: 1230px;
$gap-width: 40px;
$site-bleed: 20px;
$border-radius: 3px;

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Logo
$logo: 'DLC_logo.png';
$logo-width: 297px;
$logo-height: 132px;
$logo-mobile: 'DLC_logo.png';
$logo-mobile-width: 119px;
$logo-mobile-height: 53px;

// Typography
$font-family-base: 'Adelle Sans W05', sans-serif;
$font-weight-base: 300;
$font-weight-bold: 700;
$headings-font-family: 'Freight Sans Compressed W01', sans-serif;
$headings-font-weight: 600;
$headings-text-transform: uppercase;
$headings-letter-spacing: 0.1rem;

// Buttons
$btn-font-family: $headings-font-family;
$btn-text-transform: uppercase;
$btn-padding-x: 1.2em;
$btn-padding-y: .6em;

// Admin menu
$menu-admin-enabled: false;

// Social icons
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $brand-primary;
// $social-icons-hover-colour: $brand-primary;

// Search
$header-search-enabled: false;
$header-search-input-max-width: 140px;
$header-search-margin-right: 50px;
$header-search-input-margin-right: -40px;
$header-search-input-background-colour: $brand-four;
$header-search-input-placeholder-colour: $black;
$header-search-input-border-colour: transparent;
$header-search-button-icon-colour: $brand-primary;
$header-search-input-border-width: 1px;
$header-search-button-width: 30px;
$header-search-button-height: 47px;
$header-search-button-background-colour: transparent;
$header-search-input-border-radius: 3px;
$header-search-input-padding: 9px 10px;

// Nav
$nav-font-family: $headings-font-family;
$nav-text-transform: uppercase;
$nav-background-colour: transparent;
$nav-normal-margin-bottom: 0;
$nav-breakpoint: 1230px;

// Nav top level
$nav-top-level-item-padding: 10px 16px;
$nav-top-level-item-colour: $brand-secondary;
$nav-top-level-item-hover-colour: darken($brand-primary, 20%);
$nav-top-level-chevrons-enabled: false;
$nav-top-level-item-font-size: 1.1em;

// Nav Submenus
$nav-submenu-item-colour: darken($brand-primary, 20%);

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-details-style: overlay;
$carousel-details-max-width: 600px;
$carousel-details-position-y: bottom;
$carousel-details-padding: $spacer * 2;
$carousel-details-background-colour: $brand-primary;
$carousel-heading-font-size: 3rem;
$carousel-heading-colour: #fff;
$carousel-read-more-background-colour: darken($brand-primary, 20%);
$carousel-read-more-colour: #fff;
$carousel-controls-button-border-radius: 0;

// Home Intro
$home-intro-font-family: $headings-font-family;
// $home-intro-text-transform: uppercase;
$home-intro-background-colour: transparent;
$home-intro-margin-top: 1rem;
// $home-intro-font-size: 1.4rem;
$home-intro-colour: $brand-primary;
$home-intro-text-align: center;
// $home-intro-line-height: 1.5rem;

// Card styles
$card-details-background-colour: transparent;
$card-border: 0px solid #fff;
// $card-hover-box-shadow: 0px 0px 10px #E1DDDB;

// $card-details-background-colour: #fff;
// $card-border: 7px solid #fff;
// $card-hover-border-colour: #fff;
// $card-hover-box-shadow: 0px 0px 10px #E1DDDB;

// Home features
// $home-features-background-colour: $brand-four;
$home-features-padding-top: $spacer;
$home-features-padding-bottom: $spacer * 3;
$home-features-cards-entry-animation-prefab: 1;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;

// Impact stats
$impact-stats-padding-y: $spacer * 5;
$impact-stats-figure-colour: $brand-four;
$impact-stats-background-colour: darken($brand-primary, 60%);
$impact-stats-background-image: "stats_bg.jpg";
$impact-stats-background-image-opacity: 0.2;


// Home Quick Giving
$home-quick-giving-type: simple;
$home-quick-giving-background-colour: $brand-primary;
$home-quick-giving-padding-y: $spacer * 2;
$home-quick-giving-donation-amount-background-colour: #D6F3FF;
$home-quick-giving-donate-btn-background-colour: $brand-tertiary;
$home-quick-giving-donate-btn-colour: #fff;
$home-quick-giving-donation-amount-selected-background-colour: $brand-tertiary;

// Home feeds
$home-feeds-padding-bottom: $spacer * 2;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: true;

// Newsletter
$newsletter-button-background-colour: $link-colour;

// Footer
$footer-background-colour: $black;

// Pages
$page-title-font-size: 5rem;

$blockquote-text-align: left;
$blockquote-colour: #fff;
$blockquote-font-size: 2.5em;
$blockquote-line-height: 1em;
$blockquote-padding: $spacer * 4;

// Sidebars - off
$sidebar-enabled: false;
$post-content-max-width: 900px;
$sidebar-layout: below;

// Listing pages
$listing-intro-max-width: 900px;

// Donation amounts
$donation-amount-min-width: 220px;
